import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import { computed, onBeforeMount, provide, reactive, watch } from '@vue/composition-api'
import { getCodeLabel } from '@core/utils/filter'
import { add, multiply } from '@core/utils/math/utils'

export default function useEdit() {
  const toast = useToast()
  const {
    router,
    route
  } = useRouter()

  const setModel = (id, name, key) => {
    const k = key || state.singleKey
    state.financePayment[`${k}_id`] = id
    state.financePayment[`${k}_name`] = name
  }

  const init = async () => {

    state.id = route.value.query.id
    state.inbound_item_id = route.value.query.inbound_item_id
    state.readonly = route.value.params.readonly
    provide('_disabled', state.readonly)
    if (state.id) {
      await methods.edit()
    }
    if (state.inbound_item_id){

      const res = await store.dispatch('purchasereturnbound/getReturnBound', { inbound_item_id: state.inbound_item_id })
      if (res.data.code === 0){
        state.returnBound = Object.assign({}, state.returnBound, res.data.data)
        state.returnBound.creator_name = getCodeLabel('user',state.returnBound.creator)
      }else {
        toast.error(res.data.data)
        router.go(-1)
      }
    }
  }

  onBeforeMount(async () => {
    await init()
  })

  const methods = {
    onUpdateAmount(qty, amount) {
      state.returnBound.qty = qty
      state.returnBound.subtotal = amount
      state.returnBound.truesubtotal = amount
    },
    async edit() {
      const res = await store.dispatch('purchasereturnbound/edit', { id: state.id })
      if (res.data.code === 0){
        state.returnBound = Object.assign({}, state.returnBound, res.data.data)
        state.returnBound.creator_name = getCodeLabel('user',state.returnBound.creator)
      }else {
        toast.error(res.data.data)
      }
    },

    cancel() {
      router.go(-1)
    },

    async save(status) {
      const success = await state.observer.validate()
      if (!success) {
        toast.error('请正确填写必填字段')
        return
      }
      if (state.returnBound.is_refund === '1' && !state.returnBound.attachments){
        toast.error('请添加附件！')
        return
      }
      const editableData = state.itemList.editableData
      const editingData = Object.values(editableData)
        .filter(item => !item || !item.readonly)
      if (editingData.length > 0) {
        toast.error('请保存明细列表未保存数据')
        return
      }

      if (state.itemList.filterItems.length === 0){
        toast.error('请选择商品，无明细不可保存')
        return
      }

      const items = state.itemList.items
      const params = { ...state.returnBound }
      params.status = status
      if (params.is_refund === '0') {
        params.refund_time = undefined
        params.subtotal = 0
        params.truesubtotal = 0
      }
      params.items = items
      store.dispatch('purchasereturnbound/save', params)
        .then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            router.push({ name: 'apps-purchasereturnbound-list' })
          } else {
            toast.error(res.data.data)
          }
        })
    },
    /* showSingleModal(key) {
       state.singleKey = key
       state.singleModal.show()
     },
     setCompany(key, item) {
       switch (key) {
         case 'business_unit':
           setModel(item.company_id, item.company_name, 'payee')
           break
         case 'payment_org':
           setModel(item.company_id, item.company_name, 'settlement_org')
           setModel(item.company_id, item.company_name, 'purchase_org')
           break
         case 'settlement_org':
           setModel(item.company_id, item.company_name, 'purchase_org')
           break
       }
     },
     onSelectSingle(item) {
       switch (state.singleModalObj[state.singleKey].selectType) {
         case 'company':
           setModel(item.company_id, item.company_name)
           this.setCompany(state.singleKey, item)
           break
         case 'purchase_order':
           state.financePayment['order_id'] = item.order_id
           state.financePayment['order_no'] = item.order_no
           break
       }
       state.singleModal.hide()
     },*/
    onUploaded(id, attachment, result) {
      state.returnBound[id] = result
    },
  }

  const state = reactive({
    id: 0,
    inbound_item_id:undefined,
    readonly: false,
    is_refund_disabled: false,
    returnBound: reactive({
      returnbound_id: undefined,
      returnbound_no: undefined,
      creator_name: undefined,
      reason: undefined,
      is_refund: '',
      inbound_no: undefined,
      order_no: undefined,

      supplier_name: undefined,
      supplier_contact_name: undefined,
      supplier_contact_mobile: undefined,
      responsibility: undefined,
      delivery_method: undefined,
      return_time: undefined,
      qty: undefined,
      trueqty: undefined,

      refund_time: undefined,
      subtotal: undefined,
      truesubtotal: undefined,
      remark: undefined,
      attachments: undefined,
    }),

    //singleModal
    // singleModal: undefined,
    // companySelect: undefined,
    // singleKey: 'payee',

    //
    itemList: undefined,

    /*singleModalObj: {
      business_unit: {
        modalName: '往来单位',
        selectType: 'company',
        companyType: 'Supplier'
      },
      payee: {
        modalName: '收款单位',
        selectType: 'company',
        companyType: 'Supplier'
      },
      payment_org: {
        modalName: '付款组织',
        selectType: 'company',
        companyType: 'Group',
      },
      settlement_org: {
        modalName: '结算组织',
        selectType: 'company',
        companyType: 'Group'
      },
      purchase_org: {
        modalName: '采购组织',
        selectType: 'company',
        companyType: 'Group'
      },
      purchase_order: {
        modalName: '采购订单',
        selectType: 'purchase_order',
        size:"xl"
      },
    },*/
    observer: undefined,

  })

  const page = reactive({
    basicInfo: {
      returnbound_no: {
        type: 'input',
        attrs: {
          id: 'returnbound_no',
          label: '申请编号',
          readonly: true,
          placeholder: '保存后自动填充',
        },
      },
      creator_name: {
        type: 'input',
        attrs: {
          id: 'creator_name',
          label: '申请人',
          readonly: true,
        },
      },
      reason: {
        type: 'select',
        attrs: {
          id: 'reason',
          clearable:false,
          label: '退货原因',
          optionsType: 'purchase_returnbound_reason',
          rules: 'required',
        },
      },
      is_refund: {
        type: 'select',
        attrs: {
          id: 'is_refund',
          label: '是否退款',
          clearable:false,
          disabled: computed(() => state.is_refund_disabled),
          optionsType: 'yesno',
          rules: 'required',
        },
      },
      inbound_no: {
        type: 'input',
        attrs: {
          id: 'inbound_no',
          label: '入库编号',
          readonly: true,
        },
      },
      order_no: {
        type: 'input',
        attrs: {
          id: 'order_no',
          label: '订单编号',
          readonly: true,
        },
      },

    },

    returnBoundInfo: {
      supplier_name: {
        type: 'input',
        attrs: {
          id: 'supplier_name',
          label: '往来单位',
          readonly: true,
        },
      },
      supplier_contact_name: {
        type: 'input',
        attrs: {
          id: 'supplier_contact_name',
          label: '收货人',
          // readonly: true,
        },
      },
      supplier_contact_mobile: {
        type: 'input',
        attrs: {
          id: 'supplier_contact_mobile',
          label: '联系电话',
          // readonly: true,
        },
      },
      responsibility: {
        type: 'select',
        attrs: {
          id: 'responsibility',
          label: '费用承担',
          optionsType: 'purchase_returnbound_responsibility',
          // rules: 'required',
        },
      },
      delivery_method: {
        type: 'select',
        attrs: {
          id: 'delivery_method',
          label: '物流类型',
          optionsType: 'delivery_method_purchase_returnbound',
          // rules: 'required',
        },
      },
      return_time: {
        type: 'datePicker',
        attrs: {
          id: 'return_time',
          label: '退货日期',
          rules: 'required',
        },
      },
      qty: {
        type: 'input',
        attrs: {
          id: 'qty',
          label: '总退货数量',
          readonly: true,
        },
      },
      /*trueqty: {
        type: 'input',
        attrs: {
          id: 'trueqty',
          label: '实际数量',
          readonly: true,
        },
      },*/
    },

    refundInfo: {
      refund_time: {
        type: 'datePicker',
        attrs: {
          id: 'refund_time',
          label: '退款日期',
          rules: 'required',
        },
      },
      subtotal: {
        type: 'input',
        attrs: {
          id: 'subtotal',
          label: '应退总金额',
          readonly: true,
        },
      },
      truesubtotal: {
        type: 'input',
        attrs: {
          id: 'truesubtotal',
          label: '实退总金额',
        },
      },
      pay_object: {
        type: 'input',
        attrs: {
          id: 'pay_object',
          label: '支付对象',
          rules: 'required',
        },
      },

    },
  })

  watch(() => state.returnBound.reason, (newVal) => {
    state.is_refund_disabled = newVal === '1'
    if (newVal === '1'){
      state.returnBound.is_refund = '0'
    }
  })

  watch(() => state.returnBound.is_refund, () => {
    state.returnBound.subtotal = state.itemList.filterItems.reduce((acc, cur) => add(acc, multiply(cur.qty,cur.product_cost_tax)), 0)
    state.returnBound.truesubtotal = state.itemList.filterItems.reduce((acc, cur) => add(acc, multiply(cur.qty,cur.product_cost_tax)), 0)
  })

  return {
    state,
    methods,
    page,
  }
}
