<template>
  <b-card
  >
    <!-- form -->
    <b-form id="financePaymentForm" class="edit-form mt-2">
      <ValidationObserver ref="observer">
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">基本信息</div>
          </b-col>
          <b-col v-for="(value,name,index) in basicInfo" :key="index + '1'" md="4">
            <xy-form :prop-data="value" v-model="returnBound[name]"></xy-form>
          </b-col>
          <b-col md="12">
            <div class="inner-card-title">明细列表</div>
          </b-col>
          <b-col>
            <purchase-returnbound-item-list ref="itemList" :id="id" :inbound_item_id="inbound_item_id"
                                            @updateAmount="onUpdateAmount"
            >
            </purchase-returnbound-item-list>
          </b-col>

          <b-col md="12">
            <div class="inner-card-title">退货信息</div>
          </b-col>
          <b-col v-for="(value,name,index) in returnBoundInfo" :key="index + '2'" md="4">
            <xy-form :prop-data="value" v-model="returnBound[name]"></xy-form>
          </b-col>
        </b-row>
        <b-row v-if="returnBound.is_refund === '1'">
          <b-col md="12">
            <div class="inner-card-title">退款信息</div>
          </b-col>
          <b-col v-for="(value,name,index) in refundInfo" :key="index + '3'" md="4">
            <xy-form :prop-data="value" v-model="returnBound[name]"></xy-form>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">其他信息</div>
          </b-col>
          <b-col md="12">
            <xy-textarea label-cols="1" id="remark" label="备注" :val.sync="returnBound.remark" rules="required"/>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                :class="`mb-1${returnBound.is_refund === '1' ? ' required' : ''}`"
            >
              <attachment-upload theme="files"
                                 :readonly="readonly"
                                 attachment_id="attachments"
                                 :id="returnBound.attachments"
                                 object_type="purchase_returnbound"
                                 :object_id="returnBound.returnbound_id"
                                 placeholder="最好是银行回执单"
                                 @change="onUploaded"
                                 v-if="returnBound.loaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>

      <b-row>
        <b-col
            cols="12"
            class="mt-50"
        >
          <xy-button
              variant="primary"
              class="mr-1"
              @click="save(1)"
          >
            保存
          </xy-button>
          <b-button
              class="mr-1"
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
          <xy-button
              variant="primary"
              @click="save(2)"
          >
            保存并提交
          </xy-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { onUnmounted, toRefs } from '@vue/composition-api'
import store from '@/store'
import { getCode, getCodeColor, getCodeLabel, getCodeOptions, toDate, toTime } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import useEdit from './useEdit'
import XyForm from '@/views/components/xy/XyForm'
import XyTextarea from '@/views/components/xy/XyTextarea'
import XyButton from '@/views/components/xy/XyButton'
import purchasereturnboundStore from './purchasereturnboundStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import PurchaseReturnboundItemList from '@/views/apps/purchasereturnbounditem/PurchaseReturnboundItemList'

export default {
  components: {
    PurchaseReturnboundItemList,
    AttachmentUpload,
    XyButton,
    XyTextarea,
    XyForm,
    CompanySelect
  },
  setup() {
    // Register module
    if (!store.hasModule('purchasereturnbound')) store.registerModule('purchasereturnbound', purchasereturnboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchasereturnbound')) store.unregisterModule('purchasereturnbound')
    })

    const {
      methods: useMethods,
      state: useState,
      page: usePage,
    } = useEdit()

    return {
      ...toRefs(useState),
      ...toRefs(usePage),
      ...useMethods,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss">
</style>
